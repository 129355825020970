<template>
  <v-dialog v-model="showModalAgregarLote" persistent max-width="600">
    <v-form ref="formLote" @submit.prevent="createLote" class="mt-4">
      <v-card class="py-4">
        <v-card-text>
          <h5 class="text-h5 secondary--text mb-4">Agregar lote</h5>

          <v-text-field
            outlined
            label="Nombre del lote *"
            v-model="form.nombre"
            required
            :rules="[rules.required]"
          />
          <v-textarea
            outlined
            label="Descripción"
            v-model="form.descripcion"
            auto-grow
            counter="500"
            maxlength="500"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            outlined
            color="secondary"
            min-width="35%"
            @click="setShowModalAgregarLote(false), clearData()"
            >Cancelar</v-btn
          >
          <v-btn solid color="secondary" min-width="35%" type="submit"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "AgregarLoteComponent",
  data: () => ({
    form: {
      nombre: null,
      descripcion: null,
    },
    rules: {
      required: (value) => !!value || "Este es un campo requerido",
    },
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["showModalAgregarLote"]),
  },
  methods: {
    ...mapMutations("procesoCompraDoc", ["setShowModalAgregarLote"]),
    ...mapActions("procesoCompraDoc", ["guardarDatosLote"]),
    clearData() {
      this.form.nombre = null;
      this.form.descripcion = null;
      this.$refs.formLote.reset();
    },
    async createLote() {
      if (this.$refs.formLote.validate()) {
        await this.guardarDatosLote({
          id_proceso_compra: this.$route.params.idProceso,
          body: this.form,
        });
        this.clearData();

      }
    },
  },
};
</script>
