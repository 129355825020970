<template>
  <v-dialog v-model="show" width="1400" scrollable persistent >
    <v-card>
      <v-card-title><div class="text-center" style="width: 100%;">Fuentes de financiamiento</div></v-card-title>
      <v-card-text>
        <div>
          <v-data-table
            :headers="HEADER_CONST"
            :items="solicitud_financt_unit_var"
          >
            <!-- Slot monto asignado -->
            <template v-slot:[`item.FondosSolicitudNecesidad.monto`]="{ item }">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.FondosSolicitudNecesidad.monto)
              }}
            </template>
          </v-data-table>
        </div>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn 
                icon 
                @click.stop="cerrarModal" 
                class="close-btn"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "modalDetalleFondo",
  data: () => ({
    show_modal: false,
    solicitud_financt_unit_var: [],
    HEADER_CONST: [
      {
        align: "left",
        text: "Código presupuestario",
        value: "Fondo.cifrado_presupuestario",
      },
      {
        align: "left",
        text: "Monto ($)",
        value: "FondosSolicitudNecesidad.monto",
      },
      {
        align: "left",
        text: "Tipo",
        value: "Fondo.TipoFondo.nombre",
      },
    ],
  }),
  methods: {
    async cerrarModal(){
      this.$emit('cerrar')
    },
    async FetchDetailSolicitudNecesidadFtn(id_solicitud_var = null) {
      const response = await this.services.PacServices.getSolicitudPAC(
        id_solicitud_var ??
        this.id_solicitud_var,
      );

      if (response) {
        this.solicitud_financt_unit_var = response?.data?.FinanciamientoUnidads;
      }
    },
  },
  components: { ConfirmationDialog },
  props: {
    id_proceso: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
    },
    id_solicitud_var: {
      type: Number,
    },
  },
};
</script>
<style>
.close-btn {
  position: absolute;
  top: 10px; 
  right: 10px; 
  z-index: 1000;
}
</style>