<template>
  <v-card>
    <v-card-actions>
      <v-row>
        <v-col>
          <ValidarEdicion
            :id_proceso="+this.id_proceso"
            :id_modificativa="7"
            @validar="validar"
          />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <v-row>
        <v-col>
          <v-chip :color="estado_proceso?.color" class="white--text">
            Actualmente en: {{ estado_proceso?.nombre }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            :items="estados"
            item-text="nombre"
            item-value="id"
            v-model="id_seguimiento"
            outlined
            label="Seleccione el estado"
            :disabled="!validado"
            rounded
          />
        </v-col>
        <v-col cols="12" md="4" v-if="requiere_documento">
          <v-file-input
            v-model="file"
            label="Adjunte el documento por favor"
            outlined
            rounded
            accept=".pdf"
            @change="validateFile"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            :disabled="!validado || !id_seguimiento"
            class="secondary mt-2"
            @click.stop="show_modal = true"
          >
            Modificar seguimiento
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <ConfirmationDialog
      :show="show_modal"
      title="¿Deseas modificar el registro?"
      message="El cambio es reversible, pero se estará registrando cada acción realizada"
      btnConfirmar="Continuar"
      @close="show_modal = false"
      @confirm="modificarEstadoProceso"
    />
  </v-card>
</template>
<script>
import { convertToFormData } from "@/utils/data";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import ValidarEdicion from "./ValidarEdicion.vue";
export default {
  name: "modificarEstadoProceso",
  components: { ValidarEdicion, ConfirmationDialog },
  computed: {
    id_proceso() {
      return this?.$route?.params?.idProceso || 0;
    },
    requiere_documento() {
      return [44, 77].includes(this.id_seguimiento);
    },
  },
  data: () => ({
    validado: false,
    show_modal: false,
    id_seguimiento: null,
    estados: [],
    estado_proceso: null,
    file: null,
  }),
  methods: {
    validar(valor) {
      this.validado = valor;
    },
    async obtenerEstados() {
      const { data } =
        await this.services.cambioEstadoProcesoServices.obtenerEstados();
      this.estados = data;
    },
    async obtenerEstadoProceso() {
      const { data } =
        await this.services.cambioEstadoProcesoServices.obtenerEstadoProceso(
          this.id_proceso
        );
      this.estado_proceso = data;
    },
    async modificarEstadoProceso() {
      const formData = convertToFormData({ file: this.file });

      await this.services.cambioEstadoProcesoServices.modificarEstadoProceso(
        this.id_proceso,
        this.id_seguimiento,
        formData
      );
      this.id_seguimiento = null;
      this.show_modal = false;
      await this.obtenerEstadoProceso();
    },
    validateFile() {
      const file = this.file;
      if (file && file.type !== "application/pdf") {
        this.form.file = null;
        return this.pushAppMessage({
          message: "El documento debe ser pdf",
          type: "warning",
        });
      }
    },
  },
  async created() {
    await this.obtenerEstados();
    await this.obtenerEstadoProceso();
  },
};
</script>
