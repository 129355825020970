<template>
  <v-dialog v-model="abrirModal" persistent max-width="700">
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center mb-4">
        <v-icon class="mr-2">mdi-information-slab-circle</v-icon>
          Información complementaria de la etapa
      </v-card-title>
      <v-divider class="mb-5" />
      <v-card-text>
        <div v-if="hasDataEtapa">
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Etapa</p>
              <p> {{ dataDetalle?.etapa ?? '-' }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Cantidad a pagar</p>
              <p>$
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(dataDetalle?.cantidad_pagar)
                }}
              </p>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Fecha posible de recepción</p>
              <p>{{ dataDetalle?.fecha_inicio ? moment(dataDetalle?.fecha_inicio).format("DD/MM/YYYY hh:mm a") : '-' }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Fecha recepción</p>
              <p>
                {{ dataDetalle?.fecha_fin ? moment(dataDetalle?.fecha_fin).format("DD/MM/YYYY hh:mm a") : '-' }}
              </p>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Estado</p>
              <p>{{ dataDetalle?.completado === true ? 'Entregado' : 'Pendiente' }}</p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Usuario que registra</p>
              <p>{{ dataDetalle?.usuario?.email ?? '-' }}</p>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Observación</p>
              <p>{{ dataDetalle?.descripcion  ?? '-' }} </p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Descripción de la entrega</p>
              <p>{{ dataDetalle?.descripcion_entrega ?? '-' }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Ponderación de calificación</p>
              <p>{{ dataDetalle?.calificacion?.ponderacion ?? '-' }} </p>
            </v-col>
            <v-col cols="6">
              <p class="mb-0 font-weight-bold">Fecha que registra calificación</p>
              <p>{{ dataDetalle?.calificacion?.fecha_hora ? moment(dataDetalle?.calificacion.fecha_hora).format("DD/MM/YYYY hh:mm a") : '-' }} </p>
            </v-col>
          </v-row>

        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center mt-6">
        <v-btn class="primary mr-4" @click.stop="$emit('cerrar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import moment from "moment";

export default {
  name: "modalComplementoEtapa",
  components: {
    DataTableComponent,
  },

  props: {
    abrirModal: {
      type: Boolean,
      default: false,
    },
    dataDetalle: {
      type: [Object, Array],  // Acepta tanto un objeto como un arreglo
      default: () => ({}),
    },
  },

  data: () => ({
  }),
  computed: {
    hasDataEtapa() {
      return this.dataDetalle && Object.keys(this.dataDetalle).length > 0;
    },
  },
  methods: {
    getColor(status_name) {
      const status_color = status_name === true ? 'Entregada' : 'Pendiente'
      switch (status_color) {
        case "Pendiente":
          return "pendiente";
        case "Entregada":
          return "vigente";
      }
    },
  },
};
</script>
