<template>
  <section fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn
            block
            @click="inviteDialog = true"
            color="secondary"
            dark
            :disabled="!permiteEdicion"
        >
          Invitar
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-data-table
            :headers="filteredHeaders"
            :items="participantes_peo"
        >
          <template v-slot:[`item.email`]="{ item }">
            {{ item.usuario?.Empleado?.Persona?.primer_nombre }} {{ item.usuario?.Empleado?.Persona?.segundo_nombre }} -
            {{ item.usuario?.email }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    :disabled="!permiteEdicion"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      (removerDialog = true), (participante_seleccionado = item)
                    "
                >
                  mdi-close
                </v-icon>
              </template>
              <span> Eliminar </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="removerDialog" persistent max-width="600">
      <v-card class="pa-4" max-width="600">
        <v-row>
          <v-col cols="12">
            <p class="text-h5 px-4 secondary--text mb-0">
              Remover participante
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="px-4">¿Está seguro que desea remover el participante?</p>
            <p class="px-4 font-weight-bold">
              {{ participante_seleccionado?.usuario?.email }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="px-4">
            <v-textarea
                label="Justificación *"
                outlined
                row-height="30"
                rows="4"
                auto-grow
                clearable
                counter="500"
                :error-messages="justificacionErrors"
                v-model="justificacion"
            />
          </v-col>
        </v-row>
        <v-row class="px-4 mb-2">
          <v-btn
              outlined
              color="secondary"
              @click="(removerDialog = false), (participante_seleccionado = {})"
              v-if="!actionLoading"
          >Cancelar
          </v-btn
          >
          <v-btn class="mx-4" dark color="secondary" @click="deleteParticipante"
                 :loading="actionLoading"
          >Remover
          </v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="inviteDialog" persistent max-width="700">
      <v-card class="pa-4" max-width="700">
        <v-row>
          <v-col cols="12">
            <p class="text-h5 px-4 secondary--text mb-0">
              Agregar participante PEO
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="px-4">Digite el correo del miembro que desea agregar</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="px-4">
            <v-autocomplete
                label="Nombre o correo electrónico *"
                outlined
                :items="usuarios_proceso_list"
                item-text="email"
                item-value="id"
                clearable
                :error-messages="participanteErrors"
                v-model="participante_data.id_usuario"
            />
          </v-col>
        </v-row>
        <v-row class="px-4 mb-2">
          <v-btn
              outlined
              color="secondary"
              @click="
                (inviteDialog = false), (participante_data.id_usuario = null)
              "
              v-if="!actionLoading"
          >Cancelar
          </v-btn
          >
          <v-btn
              @click="guardarParticipante"
              class="mx-4"
              dark
              color="secondary"
              :loading="actionLoading"
          >Agregar
          </v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import {mapState} from "vuex";
import {required} from "vuelidate/lib/validators";

export default {
  name: "ConfiguracionPEOComponent",
  props: {
      permiteEdicion: {
        type: Boolean,
      }
    },
  data: () => ({
    headers: [
      {text: "Participantes", align: "start", value: "email"},
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
    removerDialog: false,
    inviteDialog: false,
    usuarios_proceso_list: [],
    participante_data: {
      id_usuario: null,
      id_proceso: null,
    },
    participantes_peo: [],
    participante_seleccionado: {},
    justificacion: null,
    actionLoading: false,
  }),
  validations: {
    participante_data: {
      id_usuario: {required},
    },
    justificacion: {required},
  },
  computed: {
    ...mapState("procesoCompra", ["info_general", "publicar_loading"]),
    filteredHeaders() {
      if (this.info_general?.id_etapa <= 6 || this.info_general.id_etapa == undefined) return this.headers;
      else return this.headers.filter((target) => target.value !== "acciones");
    },
    participanteErrors() {
      const errors = [];
      if (!this.$v.participante_data.id_usuario.$dirty) return errors;
      !this.$v.participante_data.id_usuario.required &&
      errors.push("El campo es requerido");
      return errors;
    },
    justificacionErrors() {
      const errors = [];
      if (!this.$v.justificacion.$dirty) return errors;
      !this.$v.justificacion.required &&
      errors.push("El campo es justificación requerido");
      return errors;
    },
  },
  methods: {
    async getUsuarios() {
      const {status, data} = await this.services.Paac.getUsuariosProceso();

      if (status == 200) {
        this.usuarios_proceso_list = data.map((col) => ({
          id: col.id,
          name: `${col.primer_nombre} ${col.segundo_nombre}`,
          email: col.email,
        }));
      }
    },
    async guardarParticipante() {
      this.actionLoading = true;
      this.$v.participante_data.$touch();
      if (!this.$v.participante_data.$invalid) {
        try {
          this.participante_data.id_proceso = Number(this.$route.params.idProceso);
          const {status} = await this.services.Paac.postParticipantePEO(
              this.participante_data
          );
          if (status === 201) {
            this.pushAppMessage({
              show: true,
              message: "Participante agregado correctamente",
              type: "success",
            });
          }
        } catch (e) {
          console.log(e)
        }
        await this.getParticipantes();
        this.inviteDialog = false;
        this.participante_data.id_usuario = null;
        this.$v.participante_data.$reset();
      }
      this.actionLoading = false;
    },
    async getParticipantes() {
      const {status, data} = await this.services.Paac.getParticipantesPEO(
          this.$route.params.idProceso
      );

      if (status == 200) {
        this.participantes_peo = data;
      }
    },
    async deleteParticipante() {
      this.actionLoading = true;
      this.$v.justificacion.$touch();
      if (!this.$v.justificacion.$invalid) {
        const {status} = await this.services.Paac.deleteParticipantePEO(
            this.participante_seleccionado.id,
            {justificacion: this.justificacion}
        );

        if (status == 204) {
          this.temporalAlert({
            show: true,
            message: "Participante eliminado correctamente",
            type: "success",
          });

          this.getParticipantes();
          this.removerDialog = false;
          this.participante_seleccionado = {};
          this.$v.justificacion.$reset();
        }
      }
      this.actionLoading = false;
    },
  },
  created() {
    this.getUsuarios();
    this.getParticipantes();
  },
};
</script>
