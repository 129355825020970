<template>
  <section>
    <v-dialog persistent v-model="show" max-width="800">
      <v-card>
        <v-card-title>
          <p class="text-h5 secondary--text">Crear proceso nuevo</p>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Código interno del proceso"
                v-model="nuevoProceso.codigo_interno"
                :error-messages="codigoProcesoError"
                @input="$v.nuevoProceso.codigo_interno.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Nombre del proceso"
                v-model="nuevoProceso.nombre_proceso"
                :error-messages="nombreProcesoError"
                @input="$v.nuevoProceso.nombre_proceso.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="nuevoProceso.id_forma_contratacion"
                :items="modalidades"
                item-text="nombre"
                item-value="id"
                label="Modalidad de compra *"
                outlined
                @blur="$v.nuevoProceso.id_forma_contratacion.$touch()"
                @input="$v.nuevoProceso.id_forma_contratacion.$touch()"
                :error-messages="modalidadError"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="text-center">
              <p class="mb-0 text-start">Inicio del proceso</p>
              <v-date-picker
                v-model="picker"
                type="month"
                locale="es-Es"
                header-color="#404a7a"
                style="margin-right: 40px; margin-top: 15px"
                :min="picker ? picker : moment().format('YYYY-MM-DD')"
              />
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <p class="mb-0 text-start">Finalización del proceso</p>
              <v-date-picker
                :min="picker"
                :disabled="picker == ''"
                v-model="picker2"
                type="month"
                locale="es-Es"
                style="margin-top: 15px; color-text: white"
                header-color="#404a7a"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="py-5">
          <v-row class="mt-8 mx-2 display-flex">
            <v-col cols="12" sm="4">
              <v-btn block outlined color="secondary" @click="close()"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn block color="secondary" @click="guardarProceso"
                >Crear proceso</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalProcesoCreado" persistent max-width="600">
      <v-card>
        <section>
          <v-card-text>
            <v-row justify="center" class="mt-2">
              <v-icon color="success" x-large>mdi-check-circle</v-icon>
            </v-row>
            <div class="d-flex flex-column align-center mt-5">
              <span>El nuevo proceso ha sido creado exitosamente</span>
              <span class="text-h6 secondary--text mb-5">{{
                pro?.codigo_proceso
              }}</span>
            </div>
            <v-row class="mt-2 mx-2 d-flex justify-center">
              <v-col cols="12" sm="4">
                <v-btn block outlined color="secondary" @click="cerrarModal()"
                  >Volver</v-btn
                >
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn
                  block
                  color="secondary"
                  @click="
                    $router.replace({
                      name: 'pac-procesos-configuracion',
                      params: { idProceso: pro.id },
                    })
                  "
                >
                  Ir a configurarlo
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </section>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import moment from "moment";
import { required, helpers } from "vuelidate/lib/validators";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);
const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);

export default {
  name: "ProcesoNuevoComponent",
  props: ["show", "modalidades", "close", "data", "action"],
  components: { MoneyTextFieldComponent },
  data: () => ({
    nuevoProceso: {
      suma_mercancia: 0,
    },
    picker: null,
    picker2: null,
    modalProcesoCreado: false,
    pro: {},
  }),
  validations: {
    nuevoProceso: {
      codigo_interno: { letter },
      nombre_proceso: { required, letter },
      id_forma_contratacion: { required },
      suma_mercancia: { required, priceDecimals },
    },
  },
  computed: {
    codigoProcesoError() {
      const errors = [];
      if (!this.$v.nuevoProceso.codigo_interno.$dirty) return errors;
      !this.$v.nuevoProceso.codigo_interno.letter &&
        errors.push(
          "El código del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    nombreProcesoError() {
      const errors = [];
      if (!this.$v.nuevoProceso.nombre_proceso.$dirty) return errors;
      !this.$v.nuevoProceso.nombre_proceso.required &&
        errors.push("El nombre del proceso es requerido");
      !this.$v.nuevoProceso.nombre_proceso.letter &&
        errors.push(
          "El nombre del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    montoErrors() {
      const errors = [];
      if (!this.$v.nuevoProceso.suma_mercancia.$dirty) return errors;
      !this.$v.nuevoProceso.suma_mercancia.required &&
        errors.push("El campo monto es obligatorio");
      !this.$v.nuevoProceso.suma_mercancia.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      return errors;
    },
    modalidadError() {
      const errors = [];
      if (!this.$v.nuevoProceso.id_forma_contratacion.$dirty) return errors;
      !this.$v.nuevoProceso.id_forma_contratacion.required &&
        errors.push("La modalidad de compra es requerida");
      return errors;
    },
  },
  methods: {
    setFecha() {
      this.picker = moment(this.nuevoProceso.fecha_inicio_proceso).format(
        "YYYY-MM"
      );
      this.picker2 = moment(this.nuevoProceso.fecha_contratacion).format(
        "YYYY-MM"
      );
    },
    async guardarProceso() {

      this.$v.$touch();
      if (this.$v.$invalid) return;
      const proceso = {
        codigo_interno: this.nuevoProceso.codigo_interno,
        nombre: this.nuevoProceso.nombre_proceso,
        id_forma_contratacion: this.nuevoProceso.id_forma_contratacion,
        suma_mercancia: this.nuevoProceso.suma_mercancia,
        fecha_inicio: this.moment(this.picker)
          .startOf("month")
          .format("YYYY-MM-DD"),
        fecha_fin: this.moment(this.picker2)
          .endOf("month")
          .format("YYYY-MM-DD"),
        id_tipo_proceso: this.nuevoProceso.id_tipo_proceso,
        id_institucion: this.nuevoProceso.id_institucion,
      };
      const { data, status } =
        await this.services.PacProcesos.postProcesoDesierto(
          this.$route.params.idProceso,
          proceso
        )
      if (status === 200 || status === 201) {

        this.close();
        this.modalProcesoCreado = true;
        this.pro.id = data.id;
        this.pro.codigo_proceso = data.codigo_proceso;
        this.pro.id_proceso_origen = data.id_proceso_origen;
      }
    },
    cerrarModal() {
      this.modalProcesoCreado = false;
      // this.$router.push({
      //   name: "pac-procesos-configuracion",
      //   params: this.pro.id
      // })
      this.$router.push(`/pac-procesos/configuracion/${this.pro.id}`);
      // this.$emit("listar")
    },
  },
  watch: {
    data() {
      this.nuevoProceso = this.data;
    },
    picker() {
      if (!this.$route.params.idProceso) this.picker2 = this.picker;
    },
  },
  beforeUpdate() {
    this.setFecha();
  },
  created() {
    this.nuevoProceso = this.data;
    this.setFecha();
  },
};
</script>

<style></style>
