<template>
  <v-dialog v-model="showModalA" max-width="85vw" scrollable persistent>
      <v-card class="py-2 px-6">
        <div class="justify-center d-flex text-center">
          <v-col cols="3">
            <span class="text-h6 secondary--text">
              Agregar solicitudes
            </span>
          </v-col>
          <v-card-actions>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn 
                icon 
                @click.stop="cerrarModal" 
                class="close-btn"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        </div>
        <v-card-text class="py-4">
          <v-row class="mt-4">
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                  v-model="correlativoSelected"
                  label="Correlativo"
                  outlined
                  clearable
                  @input="debouncedGetSolicitud"
              />
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-autocomplete 
                v-model="unidadSelected" 
                outlined 
                clearable 
                label="Unidad solicitante" 
                item-value="id" 
                :items="unidades"
                :item-text="unidadSolicitanteText" 
                no-data-text="Sin coincidencias" 
                @click:clear="unidadSelected = null" 
                @change="getSolicitud()">
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-autocomplete 
                class="mx-0" 
                v-model="obsSelected" 
                clearable
                outlined 
                label="OBS" 
                item-text="nombre" 
                item-value="id" 
                :items="obsList" 
                no-data-text="Sin coincidencias"
                @click:clear="obsSelected = null" 
                @change="getSolicitud()">
              </v-autocomplete>
            </v-col>
          </v-row>

          <data-table-component 
            class="mb-4" 
            :headers="encabezado" 
            no-data-text="No hay datos" 
            :items="solicitudes"
            v-models:select="perPageModel" 
            v-models:pagina="pageModel" 
            :total_registros="filters.total_rows"
            @paginar="paginar"
          >
            <template #[`item.check`]="{ item }">
              <v-btn icon v-if="!listadoObsIds.includes(item.id)" @click="addSolicitud(item)">
                <v-icon color="secondary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <template #[`item.monto_asignado`]="{ item }">
              {{
                item.monto_asignado
                  ? Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(item.monto_asignado)
                  : Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(0)
              }}
            </template>
            <template #[`item.username`]="{ item }">
              {{ item?.Usuario?.Empleado?.Persona?.primer_nombre }}
              {{ item?.Usuario?.Empleado?.Persona?.primer_apellido }}
            </template>
            <template #[`item.unidad`]="{ item }">
              <b>{{ item?.unidad }}</b>
            </template>
          </data-table-component>
        </v-card-text>
        <v-card-actions class="display-flex justify-center">
          <v-col cols="4">
            <v-btn block color="secondary" @click.stop="cerrarModal">Finalizar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "modalAgregarFondo",
  components: { DataTableComponent, },
  data: () => ({
    unidades: [],
    obsList: [],
    pagination: false,
    unidadSelected: null,
    obsSelected: null,
    correlativoSelected: null,
    timeout: null,
    solicitudes: [],
    encabezado: [
        {
          text: "Acción",
          align: "center",
          value: "check",
          sortable: false,
        },
        {
          text: "Correlativo",
          value: "correlativo",
          align: "start",
          width: "10%",
        },
        {
          text: "OBS",
          value: "obs",
          align: "start",
          width: "10%",
        },
        {
          text: "Usuario solicitante",
          value: "username",
          align: "start",
        },
        {
          text: "Información adicional",
          value: "informacion_adicional",
          align: "start",
          width: "20%",
        },
        {
          text: "Unidad",
          value: "unidad",
          align: "start",
        },
        {
          text: "Monto ($)",
          value: "monto_asignado",
          align: "right",
        },
        {
          text: "Estado",
          value: "estado_solicitud",
          align: "start",
          width: "8%",
        },
      ],
      listadoObs: [],
      listadoObsIds: [],
  }),
  computed:{
    unidadSolicitanteText() {
      return unidades => `${unidades.nombre} - ${unidades.institucion}`;
    },
    ...mapState("pac", ["filters"]),
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
  },
  methods: {
    ...mapMutations("pac", ["setPage"]),
    async cargarDatos() {
      await this.getUnidades();
      await this.getOBSSegmentos();
    },
    async cerrarModal(){
      this.$emit('cerrar')
      this.unidadSelected = null;
      this.obsSelected = null;
      this.correlativoSelected = null;
      this.filters.page = 1;
      this.filters.per_page = 10;
    },
    async getUnidades() {
      const response = await this.services.SolicitudesNecesidad.getUnidadesProceso(
        this.id_proceso,
      );
      this.unidades = response.data;
    },
    debouncedGetSolicitud() {
      clearTimeout(this.timeout); // Limpiar el temporizador anterior
      this.timeout = setTimeout(() => {
        this.getSolicitud();
      }, 600);
    },
    async getSolicitud() {
      if (this.unidadSelected || this.obsSelected || this.correlativoSelected) {
        this.setPage(1);
        const response = await this.services.SolicitudesNecesidad.getSolicitudesObs(
          this.id_proceso,
          {
            ...this.filters,
            id_obs: this.obsSelected,
            id_unidad: this.unidadSelected,
            proceso: true,
            correlativo: this.correlativoSelected,
            id_estado: "2,6,7",
          }
        );
        this.solicitudes = response?.data;
        this.filters.page = Number(response.headers.page);
        this.filters.per_page = Number(response.headers.per_page);
        this.filters.total_rows = Number(response.headers.total_rows);
      } else {
        const response2 = await this.services.SolicitudesNecesidad.getSolicitudesObs(
          this.id_proceso,
          {
            ...this.filters,
            proceso: true,
            id_estado: "2,6,7",
          }
        );
        this.solicitudes = response2?.data;
        this.filters.page = Number(response2.headers.page);
        this.filters.per_page = Number(response2.headers.per_page);
        this.filters.total_rows = Number(response2.headers.total_rows);
      }
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getSolicitud();
    },
    async getOBSSegmentos() {
      const { status, data } = await this.services.PacServices.getOBSSegmentos({
        pagination: false,
      });

      if (status === 200) {
        this.obsList = data.map((col) => ({
          id: col.id,
          nombre: `${col.codigo} ${col.nombre}`,
        }));
      }
    },
    async addSolicitud(item) {
      if (this.id_proceso) {
        const { status } =
          await this.services.SolicitudesNecesidad.addSolicitudNProcesoPAC(
            this.id_proceso,
            { id_solicitud_necesidad: item.id }
          );
        if (status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Solicitud agregada con éxito",
          });
          this.getSolicitud();
        }
      } else {
        this.listadoObs.push(item);
        this.listadoObsIds.push(item.id);
      }
    },
  },
  props: {
    id_proceso: {
      type: Number,
      required: true,
    },
    showModalA: {
      type: Boolean,
    },
  },
  async created(){
    await this.cargarDatos();
  },
};
</script>
<style>
.close-btn {
  position: absolute;
  top: 10px; 
  right: 10px; 
  z-index: 1000;
}
</style>
