<template>
  <v-dialog v-model="modalRea" max-width="60vh" @click:outside="cerrarModalR">
    <v-card>
      <v-card-title class="d-flex justify-center mb-2">
        <v-icon color="secondary" large>mdi-play-circle</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex justify-center">
          <p class="text-h6 secondary--text"
            >¿Desea reanudar el proceso?</p
          >
        </v-row>
        <v-row class="d-flex justify-center mt-2">
            <p
            >De confirmar la siguiente acción, el proceso estará listo para
            reanudarse</p
          >
        </v-row>
        <v-row class=" d-flex justify-center">
          <v-file-input
            label="Justificación"
            outlined
            prepend-inner-icon="mdi-paperclip"
            prepend-icon=""
            append-icon=""
            class="col-md-11"
            accept="application/pdf"
            v-model="documento"
            :error-messages="documentoError"
            @blur="$v.documento.$touch()"
            @input="$v.documento.$touch()"
          ></v-file-input>
        </v-row>

        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="5" md="3">
              <v-btn block outlined color="secondary" @click="cerrarModalR"
                >No</v-btn
              >
            </v-col>
            <v-col cols="12" sm="5" md="3">
              <v-btn block color="secondary" @click="reanudar()" dark
                >Si, deseo
                reanudar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  props: ["modalRea"],
  data: () => ({
    documento: null,
  }),
  validations: {
    documento: { 
      required, 
      fileTypeValid, 
      fileNameValid, 
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    documentoError() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe de subir un documento");
      !this.$v.documento.fileTypeValid &&
        errors.push("El archivo debe de ser PDF");
      !this.$v.documento.fileNameValid &&
        errors.push("El nombre del archivo no debe de contener caracteres especiales");
      !this.$v.documento.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },
  },
  methods: {
    cerrarModalR() {
      this.documento = null;
      this.$emit("cerrarR");
    },
    async reanudar() {

      const formData = new FormData();
      formData.append("doc_justificacion", this.documento);
      const { status } = await this.services.PacProcesos.reanudarProceso(
        this.$route.params.idProceso,
        formData
      ).catch(() => {

      });
      if (status === 204) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Se reanudó el proceso",
        });
        this.cerrarModalR();

      }
    },
  },
};
</script>

<style></style>
