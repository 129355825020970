<template>
  <section>
    <v-dialog v-model="show" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">Cancelar proceso</v-card-title>
        <v-card-text>
          <p class="text-justify">Para realizar la cancelación del proceso de compra debe establecer el documento justificante proporcionado por la institución</p>

          <v-form ref="formMotivo" class="mt-6">
            <v-file-input
              outlined
              label="Justificación *"
              v-model="form.motivo"
              accept="application/pdf"
              prepend-icon=""
              :rules="[v => !!v || 'Este campo es requerido']"
              prepend-inner-icon="mdi-paperclip"
              placeholder="Seleccione el documento justificante"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="cerrar" min-width="200">Abortar</v-btn>
          <v-btn color="secondary" solid @click="cancelarProceso">Cancelar proceso</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: 'ModalCancelarProcesoComponent',
  data: () => ({
    show: false,
    form: {
      motivo: null
    },
  }),
  methods: {
    cerrar() {
      this.$emit('hideModal')
    },
    async cancelarProceso() {
      if (this.$refs.formMotivo.validate()) {

        const formData = new FormData()
        formData.append('motivo', this.form.motivo)

        const response = await this.services.PacProcesos.cancelarProceso(this.$route.params.idProceso, formData)

        if (response.status === 200) {
          this.$emit('hideModal');
          this.$router.replace({ name: 'pac-procesos' })
          this.temporalAlert({
            show: true,
            message: 'El proceso de compra ha sido cancelado',
            type: 'success'
          });
        }
      }


    }
  },
}
</script>