<template>
  <section fluid class="mb-8">
    <h3 class="text-center text-sm-start">Configuración de lotes</h3>
    <p class="small-text mb-0">
      Después de agregar un lote se deben establecer las especificaciones dentro
      del lote.
    </p>
    <p class="small-text mb-0">
      La creación de especificaciones requiere de que se hayan agregado las
      solicitudes de compra correspondientes al proceso.
    </p>
    <v-row class="mt-4">
      <v-col cols="12" sm="4">
        <div class="text-center text-md-start pb-3">
          <v-col>
            <v-btn
              :disabled="!permiteEdicion && !esRolConfigurarLotes"
              v-if="
                ([11].includes(procesoData.id_seguimiento_proceso) &&
                  !esRolConfigurarLotes) ||
                (existeAdjuntoModificacionLote && esRolConfigurarLotes)
              "
              color="secondary"
              class="text-no-style button-extra text-center mx-auto"
              dark
              style="width: 100%"
              @click="setShowModalAgregarLote(true)"
            >
              <v-icon>mdi-plus</v-icon>
              Agregar lote
            </v-btn>
          </v-col>

          <template
            v-if="existeAdjuntoModificacionLote && esRolConfigurarLotes"
          >
            <v-col>
              <v-btn
                color="secondary"
                class="text-no-style button-extra text-center mx-auto"
                dark
                style="width: 100%"
                @click="obtenerAdjuntoPermitirModificaciones"
              >
                <v-icon>mdi-file-eye"</v-icon>
                Visualizar adjunto de modificaciones
              </v-btn>
            </v-col>
          </template>
          <template
            v-else-if="
              !existeAdjuntoModificacionLote &&
              [3].includes(procesoData.id_seguimiento_proceso) &&
              esRolConfigurarLotes
            "
          >
            <v-col>
              <v-btn
                color="secondary"
                class="text-no-style button-extra text-center mx-auto"
                dark
                style="width: 100%"
                @click="modalAdjuntoModificaciones"
              >
                <v-icon>mdi-tools</v-icon>
                Aplicar modificaciones
              </v-btn>
            </v-col>
          </template>

          <v-col>
            <v-btn
              v-if="esRolConfigurarLotes || esRolLecturaConfigurarLotes"
              color="secondary"
              class="text-no-style button-extra text-center mx-auto"
              dark
              style="width: 100%"
              @click="modalAgregarParticipaciones"
            >
              <v-icon>mdi-eye-plus-outline</v-icon>
              Revisión de participaciones
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-if="esRolConfigurarLotes || esRolLecturaConfigurarLotes"
              color="secondary"
              class="text-no-style button-extra text-center mx-auto"
              dark
              style="width: 100%"
              @click="modalRevisionEvaluacion"
            >
              <v-icon>mdi-check-outline</v-icon>
              Revisión de evaluación
            </v-btn>
          </v-col>
        </div>

        <v-card class="mt-4">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="9" class="pr-md-1 pr-lg-2">
                <v-text-field
                  outlined
                  dense
                  label="Buscar lote"
                  v-model="filterLote"
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="3" class="pl-md-1 pl-lg-2">
                <v-btn
                  solid
                  block
                  color="secondary"
                  class="mb-3"
                  @click="
                    getLotes({
                      id_proceso_compra: $route.params.idProceso,
                      filters: filterLote,
                    })
                  "
                >
                  <v-icon color="white">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <p v-if="lotes.length === 0" class="text-center">
              No se han encontrado lotes configurados
            </p>

            <v-list
              nav
              dense
              class="px-0"
              style="max-height: 450px; overflow-y: auto"
            >
              <v-list-item-group v-model="loteSeleccionado" color="primary">
                <v-list-item v-for="(lote, i) in lotes" :key="i" outlined>
                  <v-list-item-content>
                    <v-list-item-title v-text="lote.nombre"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      v-if="
                        (procesoData.id_seguimiento_proceso === 11 &&
                          !esRolConfigurarLotes &&
                          permiteEdicion) ||
                        (esRolConfigurarLotes && existeAdjuntoModificacionLote)
                      "
                      icon
                      @click.prevent="
                        setShowModalDeleteLote(true), setIdLoteAccion(lote.id)
                      "
                    >
                      <v-icon
                        :color="loteSeleccionado === i ? 'white' : 'secondary'"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="8">
        <v-card
          v-if="
            typeof loteSeleccionado == 'number' &&
            datosLoteSeleccionado.nombre !== null
          "
        >
          <v-card-text>
            <v-form>
              <v-text-field
                outlined
                label="Nombre del lote *"
                v-model="datosLoteSeleccionado.nombre"
              />
              <v-text-field
                outlined
                label="Descripción"
                v-model="datosLoteSeleccionado.descripcion"
              />
              <v-btn
                outlined
                color="secondary"
                class="mr-4"
                min-width="30%"
                :disabled="!permiteEdicion && !esRolConfigurarLotes"
                @click="
                  actualizarDatosLote({
                    id_lote: datosLoteSeleccionado.id,
                    nombre: datosLoteSeleccionado.nombre,
                    descripcion: datosLoteSeleccionado.descripcion,
                    id_proceso_compra: datosLoteSeleccionado.id_proceso_compra,
                    filters: filterLote,
                  })
                "
                >Actualizar lote</v-btn
              >
              <v-btn
                outlined
                v-if="this.haveRole('ROLE_SUPER_ADMIN')"
                color="secondary"
                class="mr-4"
                min-width="30%"
                @click="
                  separarAgrupador({
                    id_lote: datosLoteSeleccionado.id,
                    id_proceso_compra: datosLoteSeleccionado.id_proceso_compra,
                  })
                "
                >Separar en lote por agrupador</v-btn
              >
              <!-- <v-btn
                outlined
                v-if="this.haveRole('ROLE_SUPER_ADMIN')"
                color="secondary"
                class="mr-4"
                min-width="30%"
                @click="
                  separarEspecifica({
                    id_lote: datosLoteSeleccionado.id,
                    id_proceso_compra: datosLoteSeleccionado.id_proceso_compra,
                  })
                "
                >Separar en lote por items</v-btn
              > -->
              <v-btn
                :disabled="
                  (!permiteEdicion &&
                    esRolConfigurarLotes &&
                    [11].includes(procesoData.id_seguimiento_proceso)) ||
                  (esRolLecturaConfigurarLotes && !esRolConfigurarLotes)
                "
                color="secondary"
                min-width="30%"
                v-if="
                  [11].includes(procesoData.id_seguimiento_proceso) ||
                  (esRolConfigurarLotes && existeAdjuntoModificacionLote)
                "
                @click="setShowModalAddEspecificacion(true)"
                >Agregar especificación</v-btn
              >
            </v-form>

            <v-divider class="mt-4 mb-4" />

            <v-data-table
              :headers="headers"
              :items="agrupacionesLote"
              no-data-text="No hay datos para mostrar"
            >
              <template #[`item.monto_sub_proceso_obs`]="{ item }">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  }).format(item?.monto_sub_proceso_obs)
                }}
              </template>
              <template #[`item.acciones`]="{ item }">
                <v-btn
                  v-if="esRolConfigurarLotes && existeAdjuntoModificacionLote"
                  icon
                  @click="showModalEliminar(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal para observar las participaciones de proveedores a lotes junto a su oferta -->
    <v-dialog v-model="modalParticipaciones" max-width="85vw" persistent>
      <v-card class="py-6 px-6">
        <div class="headerIcon d-flex justify-end">
          <v-icon class="secondary--text" @click="closeModal">
            mdi-close
          </v-icon>
        </div>
        <span class="text-h6 secondary--text">
          Registro de participación de proveedores a lotes
        </span>
        <v-card-text class="py-4">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(
                participacionProveedor, proveedor
              ) in dataParticipacionProveedor"
              :key="proveedor"
            >
              <v-expansion-panel-header>
                <v-row class="d-flex align-center justify-space-between">
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 font-weight-bold">
                      {{ proveedor }}
                    </h6>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <h6 class="text-subtitle-1 font-weight-bold">
                      {{ getOfertaStatus(participacionProveedor.oferta) }}
                    </h6>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <template>
                      <v-tooltip
                        v-if="participacionProveedor.oferta.length > 0"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              loadAttachmentSolicitud(
                                participacionProveedor.oferta
                              )
                            "
                            :disabled="
                              esRolLecturaConfigurarLotes &&
                              !esRolConfigurarLotes
                            "
                          >
                            <v-icon>mdi-file-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver oferta de proveedor</span>
                      </v-tooltip>
                      <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              abrirModalAgregarOferta(
                                participacionProveedor.lotes
                              )
                            "
                            :disabled="
                              (esRolLecturaConfigurarLotes &&
                                !esRolConfigurarLotes) ||
                              (!existeAdjuntoModificacionLote &&
                                esRolConfigurarLotes)
                            "
                          >
                            <v-icon>mdi-file-alert</v-icon>
                          </v-btn>
                        </template>
                        <span>Subir oferta de proveedor</span>
                      </v-tooltip>

                      <v-tooltip
                        bottom
                        v-if="
                          existeAdjuntoModificacionLote &&
                          esRolConfigurarLotes &&
                          participacionProveedor.oferta.length > 0
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              eliminarModalCalificacion(
                                participacionProveedor.oferta
                              )
                            "
                          >
                            <v-icon>mdi-file-document-remove</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar oferta de proveedor</span>
                      </v-tooltip>

                      <v-tooltip
                        bottom
                        v-if="
                          existeAdjuntoModificacionLote &&
                          esRolConfigurarLotes &&
                          participacionProveedor.oferta.length > 0
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              abrirModalCalificacion(
                                participacionProveedor.oferta
                              )
                            "
                          >
                            <v-icon>mdi-clipboard-check-multiple</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver calificación de oferta</span>
                      </v-tooltip>
                    </template>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <template v-for="(lote, index) in participacionProveedor.lotes">
                  <div
                    :key="index"
                    class="d-flex align-center justify-space-between"
                  >
                    <div>
                      <h6 class="text-subtitle-1 font-weight-bold">
                        {{ lote.nombre }}
                      </h6>
                      <p class="mb-0 text-body-2">{{ lote.descripcion }}</p>
                    </div>
                    <v-switch
                      @change="
                        (value) =>
                          handleSwitchChange(
                            lote.id,
                            participacionProveedor.oferta[0]?.id,
                            lote.id_proceso_compra,
                            value
                          )
                      "
                      :input-value="lote.participacionLote"
                      :label="textSwitch(lote.participacionLote)"
                      color="success"
                      :disabled="
                        (esRolLecturaConfigurarLotes &&
                          !esRolConfigurarLotes) ||
                        (!existeAdjuntoModificacionLote && esRolConfigurarLotes)
                      "
                    >
                    </v-switch>
                  </div>
                  <v-divider
                    v-if="index !== participacionProveedor.lotes.length - 1"
                  />
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal para agregar una nueva oferta de un proveedor -->
    <v-dialog
      v-model="agregarOfertaAdjunto"
      width="900"
      persistent
      height="700"
    >
      <v-card height="600">
        <v-card-title class="pt-10 pl-10">
          <v-icon class="secondary--text" @click="cerrarModal">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" md="12">
            <p class="text-h6 font-museo-sans mb-0 secondary--text text-center">
              Subir un nuevo documento de oferta
            </p>
          </v-col>
          <v-form ref="formBaja" lazy-validation>
            <v-col cols="12">
              <vc-date-picker
                v-model="form.calendar"
                mode="datetime"
                locale="es"
                is24hr
                :popover="config_calendar"
                :max-date="$moment().format('YYYY-MM-DD HH:mm')"
                :valid-hours="horas_disponibles"
                @dayclick="horasDisponibles()"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    class="py-1 border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de recepción *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    :value="getFechaFin"
                    v-on="inputEvents"
                    outlined
                    clearable
                    :error-messages="dataVarValueErrors"
                  />
                </template>
              </vc-date-picker>
            </v-col>

            <v-col cols="12">
              <v-select
                label="Tipo de Oferta *"
                outlined
                :items="ctlTipoOferta"
                item-value="id"
                item-text="nombre"
                v-model="form.idTipoOferta"
                :error-messages="tipoOfertaErrors"
                @blur="$v.form.idTipoOferta.$touch()"
              />
            </v-col>

            <v-col cols="12">
              <v-file-input
                v-model="form.adjunto"
                label="PDF"
                placeholder="Seleccione un PDF"
                outlined
                light
                @change="$v.form.adjunto.$touch()"
                @blur="$v.form.adjunto.$touch()"
                :error-messages="adjuntoErrors"
                accept="application/pdf"
              />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions class="pl-10 pr-10 mt-8">
          <v-row>
            <v-col cols="6">
              <v-btn color="primary" block @click="cerrarModal">
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                color="secondary"
                @click="crearRegistroDocumentoOferta"
              >
                Registrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal ver o agregar calificacion -->
    <v-dialog
      v-model="modalCalificacionOferta"
      width="700"
      persistent
      height="600"
    >
      <v-card height="500">
        <v-card-title class="pt-10 pl-10">
          <v-icon class="secondary--text" @click="cerrarModalCalificaciones">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" md="12">
            <p class="text-h6 font-museo-sans mb-0 secondary--text text-center">
              Visualizar la calificación de la oferta
            </p>
          </v-col>
          <v-form ref="formBaja" lazy-validation>
            <v-col cols="12">
              <v-text-field
                v-model="calificacion_oferta"
                outlined
                v-mask="'##'"
                label="Calificación de oferta"
                @input="$v.calificacion_oferta.$touch()"
                :error-messages="calificacionErrors"
              />
            </v-col>

            <v-col
              cols="12"
              class="font-museo-sans mb-0 secondary--text text-center"
            >
              Al calificar esta oferta el proveedor podrá: <br />
              1- Ser adjudicado en los lotes asignados.<br />
              2- Participar en las subastas de los lotes asignados. <br /><br />
              <p
                class="text-h6 font-museo-sans mb-0 secondary--text text-center"
              >
                ¿Desea continuar?
              </p>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions class="pl-10 pr-10 mt-8">
          <v-row>
            <v-col cols="6">
              <v-btn color="primary" block @click="cerrarModalCalificaciones">
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block color="secondary" @click="putCalificacionOferta">
                modificar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para subir un documento para modificar lotes -->
    <v-dialog v-model="modalModificarLotes" width="650" persistent>
      <v-card>
        <v-card-title>
          <v-icon
            class="secondary--text"
            @click="cerrarModalAdjuntoModificaciones"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" md="12">
            <p class="text-h6 font-museo-sans mb-0 secondary--text text-center">
              Subir documento para registrar modificación de lotes y
              participaciones
            </p>
          </v-col>
          <v-form ref="formBaja" lazy-validation>
            <v-col cols="12">
              <v-file-input
                v-model="adjuntoModificaciones"
                label="PDF"
                placeholder="Seleccione un PDF"
                outlined
                light
                accept="application/pdf"
              />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions class="pl-10 pr-10 pb-10">
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                block
                @click="cerrarModalAdjuntoModificaciones"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                color="secondary"
                @click="subirAdjuntoPermitirModificaciones"
              >
                Registrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="modalEvaluacion">
      <v-card>
        <v-card-text>
          <participacionesAuditoria :procesoData="procesoData2" />
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" @click="modalEvaluacion = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogoConfirmacion
      :show="showModalDeleteLote"
      title="¿Desea eliminar el lote seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="setShowModalDeleteLote(false), setIdLoteAccion(null)"
      @confirm="
        deleteLote({
          id_proceso_compra: $route.params.idProceso,
          filters: filterLote,
        }),
          (loteSeleccionado = null)
      "
    />

    <ConfirmationModalComponent
      :isOpen="modalDeleteAgrupacion"
      description="¿Desea eliminar el registro seleccionado?"
      @confirm="confirmarEliminacionAgrupacion"
      @cancel="modalDeleteAgrupacion = false"
    />

    <PdfModal
      :isOpen="isPdfModalOpen"
      :source-loadable="pdfLoadable"
      @on-visibility-change="handlePdfModalVisibility"
      :filename="filename"
    />

    <PdfModal
      :isOpen="isPdfModificarLotesModalOpen"
      :source-loadable="pdfModificarLotesLoadable"
      @on-visibility-change="handlePdfModificacionLotes"
      :filenameLotes="filenameLotes"
    />

    <ConfirmationModalComponent
      :isOpen="modalDeleteOferta"
      description="La eliminación de la oferta es irreversible, ¿Desea continuar?"
      @confirm="confirmarEliminacionOferta"
      @cancel="modalDeleteOferta = false"
    />

    <AgregarLoteComponent />
    <AgregarEspecificacionComponent />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AgregarLoteComponent from "./AgregarLoteComponent.vue";
import AgregarEspecificacionComponent from "./AgregarEspecificacionComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import DataTableComponent from "../../../components/DataTableComponent.vue";
import PdfModal from "@/components/PdfModal.vue";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
  isApiErrorResponse,
} from "@/utils/loadable";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { convertToFormData } from "@/utils/data";
import participacionesAuditoria from "../AuditoriaProcesos/components/ParticipantesComponent.vue";

export default {
  name: "ConfiguracionLotesComponent",
  components: {
    AgregarLoteComponent,
    AgregarEspecificacionComponent,
    dialogoConfirmacion,
    ConfirmationModalComponent,
    DataTableComponent,
    PdfModal,
    participacionesAuditoria,
  },
  validations: {
    form: {
      idTipoOferta: { required },
      adjunto: { required },
      calendar: { required },
    },
    calificacion_oferta: { maxValue: maxValue(10), minValue: minValue(1) },
  },
  props: {
    permiteEdicion: {
      type: Boolean,
    },
  },
  data: () => ({
    loteSeleccionado: null,
    especificaciones_lote: [],
    filterLote: null,
    modalDeleteAgrupacion: false,
    dataAgrupacion: null,
    modalParticipaciones: false,
    dataParticipacionProveedor: [],
    //modificacion de lotes
    modalModificarLotes: false,
    adjuntoModificaciones: null,
    existeAdjuntoModificacionLote: null,
    // Pdf modal ver adjunto para modificar lotes
    isPdfModificarLotesModalOpen: false,
    pdfModificarLotesLoadable: createLoadable(null),
    filenameLotes: "",
    //PDF
    isPdfModalOpen: false,
    pdfLoadable: createLoadable(null),
    filename: "",
    agregarOfertaAdjunto: false,
    dataCrearOfertaParticipacionProveedor: null,
    //calificacion de oferta modal
    modalCalificacionOferta: false,
    calificacion_oferta: null,
    dataOferta: null,
    //eliminar oferta de proveedor
    modalDeleteOferta: false,
    dataOfertaEliminacion: null,
    form: {
      idTipoOferta: null,
      adjunto: null,
      calendar: null,
    },
    config_calendar: {
      visibility: "click",
    },
    horas_disponibles: {},
    // headers
    headersParticipacion: [
      {
        text: "Proveedor",
        align: "center",
        value: "proveedores",
        sortable: false,
      },
      {
        text: "Lotes en los que participa",
        align: "center",
        value: "info_lote",
        sortable: false,
      },
      {
        text: "Fecha de recepción",
        align: "center",
        value: "fecha_recepcion",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
    // Catalogo de oferta
    ctlTipoOferta: [
      {
        id: 1,
        nombre: "Oferta técnica",
      },
      {
        id: 2,
        nombre: "Oferta económica",
      },
    ],
    modalEvaluacion: false,
    procesoData2: {},
  }),
  computed: {
    ...mapState("procesoCompraDoc", [
      "lotes",
      "procesoData",
      "datosLoteSeleccionado",
      "showModalDeleteLote",
    ]),
    agrupacionesLote() {
      return this.datosLoteSeleccionado?.SubProcesoObs || [];
    },
    nombreLote() {
      return this.datosLoteSeleccionado?.nombre;
    },
    descripcionLote() {
      return this.datosLoteSeleccionado?.descripcion;
    },
    esRolConfigurarLotes() {
      return this.haveRoles(["ROLE_CONFIGURACION_LOTES_DINAC"]);
    },
    esRolLecturaConfigurarLotes() {
      return this.haveRoles(["ROLE_LECTURA_CONFIGURACION_LOTES_DINAC"]);
    },
    headers() {
      const defaultHeaders = [
        { text: "Nombre", align: "center", value: "nombre_agrupacion" },
        { text: "Detalle", align: "center", value: "especificacion" },
        { text: "Cantidad", align: "right", value: "total_sub_proceso" },
        { text: "Monto", align: "right", value: "monto_sub_proceso_obs" },
      ];
      if (this.esRolConfigurarLotes) {
        // Si el usuario tiene el rol ROLE_CONFIGURACION_LOTES_DINAC, incluir la columna de acciones
        return [
          ...defaultHeaders,
          {
            text: "Acciones",
            align: "center",
            value: "acciones",
            sortable: false,
          },
        ];
      } else {
        // Si el usuario no tiene el rol ROLE_CONFIGURACION_LOTES_DINAC, devolver los headers por defecto
        return defaultHeaders;
      }
    },
    getFechaFin() {
      return this.form.calendar
        ? this.$moment(this.form.calendar, "DD/MM/YYYY hh:mm A").format(
            "DD-MM-YYYY hh:mm A"
          )
        : "";
    },
    tipoOfertaErrors() {
      const errors = [];
      if (!this.$v.form.idTipoOferta.$dirty) return errors;
      !this.$v.form.idTipoOferta.required && errors.push("Campo requerido");
      return errors;
    },
    dataVarValueErrors() {
      const errors = [];
      if (!this.$v.form.calendar.$dirty) return errors;
      !this.$v.form.calendar.required && errors.push("Campo requerido");
      return errors;
    },
    adjuntoErrors() {
      const errors = [];
      if (!this.$v.form.adjunto.$dirty) return errors;
      !this.$v.form.adjunto.required && errors.push("Campo requerido");
      return errors;
    },
    calificacionErrors() {
      const errors = [];
      if (!this.$v.calificacion_oferta.$dirty) return errors;
      !this.$v.calificacion_oferta.minValue &&
        errors.push("La calificación debe ser mayor a cero");
      !this.$v.calificacion_oferta.maxValue &&
        errors.push("La calificación debe ser entre los números 1 y 10");
      return errors;
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", [
      "setShowModalAgregarLote",
      "setShowModalAddEspecificacion",
      "setShowModalDeleteLote",
      "setIdLoteAccion",
    ]),
    ...mapActions("procesoCompraDoc", [
      "getLotes",
      "getLote",
      "actualizarDatosLote",
      "deleteLote",
      "separarAgrupador",
      "separarEspecifica",
    ]),
    ...mapActions("procesoCompra", [
      "getObsRecepcionOfertas",
      "getObsDetalleRecepcionOfertas",
      "getSubprocesoObsRecepcionOfertas",
    ]),

    // Abrir el modal para cargar un adjunto de modificaciones
    async modalAdjuntoModificaciones() {
      this.modalModificarLotes = true;
    },
    //Subir adjunto para permitir modificaciones a lotes
    async subirAdjuntoPermitirModificaciones() {
      const idProcesoCompra = this.$route.params.idProceso;
      if ([null, ""].includes(this.adjuntoModificaciones)) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Cargar el archivo adjunto es requerido",
        });
        return false;
      }

      const payload = {
        adjunto: this.adjuntoModificaciones,
      };
      const form = convertToFormData(payload);

      const { status } =
        await this.services.PacProcesos.agregarAdjuntoModificacionLotes(
          idProcesoCompra,
          form
        );

      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "El adjunto ha sido cargado correctamente",
        });
        this.cerrarModalAdjuntoModificaciones();
        this.obtenerAdjuntoPermitirModificacionesPreCarga();
      }
    },
    // Visualizar adjunto para poder modificar lotes
    handlePdfModificacionLotes(visible) {
      this.isPdfModificarLotesModalOpen = visible;
    },
    async obtenerAdjuntoPermitirModificaciones() {
      this.isPdfModificarLotesModalOpen = true;
      const idProcesoCompra = this.$route.params.idProceso;
      toggleLoadable(this.pdfModificarLotesLoadable);
      const { data } =
        await this.services.PacProcesos.obtenerAdjuntoModificacionLotes(
          idProcesoCompra
        );
      setLoadableResponse(this.pdfModificarLotesLoadable, data, {
        isFile: true,
      });
    },
    // Metodo para pre cargar el adjunto en lotes si existe
    async obtenerAdjuntoPermitirModificacionesPreCarga() {
      const idProcesoCompra = this.$route.params.idProceso;
      const { data } =
        await this.services.PacProcesos.obtenerAdjuntoModificacionLotes(
          idProcesoCompra
        );
      if (isApiErrorResponse(data)) {
        this.existeAdjuntoModificacionLote = null;
      } else {
        this.existeAdjuntoModificacionLote = data;
      }
    },
    // Cerrar el modal para cargar un documento de modificacion de lote
    cerrarModalAdjuntoModificaciones() {
      this.adjuntoModificaciones = null;
      this.modalModificarLotes = false;
    },

    // Mostrar modal para eliminar especificaciones de un lote
    showModalEliminar(item) {
      this.dataAgrupacion = item;
      this.modalDeleteAgrupacion = true;
    },
    // eliminar especificaciones de un lote
    async confirmarEliminacionAgrupacion() {
      const idSubProcesoObs = this.dataAgrupacion.id;
      const { status } = await this.services.PacProcesos.eliminarAgrupador(
        idSubProcesoObs
      );
      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Solicitud eliminada con éxito",
        });
        this.getLotes({
          id_proceso_compra: this.$route.params.idProceso,
        });
        this.modalDeleteAgrupacion = false;
        this.loteSeleccionado = null;
      }
    },

    async modalRevisionEvaluacion() {
      const { data } =
        await this.services.PacProcesos.lotesParticipacionProveedoresEvaluacion(
          this.$route.params.idProceso
        );

      this.procesoData2 = { participantes: data };

      this.modalEvaluacion = true;
    },

    //obtener la data del modal revision de participaciones
    async modalAgregarParticipaciones() {
      this.modalParticipaciones = true;
      const idProcesoCompra = this.$route.params.idProceso;

      const { data } =
        await this.services.PacProcesos.lotesParticipacionProveedores(
          idProcesoCompra
        );
      this.dataParticipacionProveedor = data;
    },
    // Cerrar modal de revision de participaciones
    async closeModal() {
      this.modalParticipaciones = false;
    },
    //Visualizar adjunto de oferta dentro del modal de revision de participaciones
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
    async loadAttachmentSolicitud(oferta) {
      this.isPdfModalOpen = true;
      const idOfertaProveedor = oferta[0].id;
      toggleLoadable(this.pdfLoadable);
      const { data } = await this.services.PacProcesos.cargarDocumentoOferta(
        idOfertaProveedor
      );
      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
    //Cambiar estado de participacion en lotes dentro del modal de revision de participaciones
    async handleSwitchChange(id_lote, id_oferta, id_proceso_compra, value) {
      if (!id_oferta) {
        this.temporalAlert({
          show: true,
          type: "error",
          message:
            "No puede crear participación en lotes sin tener una oferta registrada.",
        });
        return;
      }
      const form = new FormData();
      form.append("id_lote", id_lote);
      form.append("id_oferta", id_oferta);
      form.append("id_proceso_compra", id_proceso_compra);
      form.append("value", value);

      const { status } =
        await this.services.PacProcesos.agregarParticipacionProveedorLote(form);
      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Participación al lote actualizada con éxito",
        });
      }
    },
    //Mostrar el estado de la oferta dentro del modal de revision de participaciones
    getOfertaStatus(ofertas) {
      if (!Array.isArray(ofertas) || ofertas.length === 0) {
        return "No agregó oferta";
      }

      return ofertas
        .map((oferta) => {
          if (oferta.puede_participar && oferta.calificacion_oferta) {
            return `Oferta evaluada y calificada con: ${oferta.calificacion_oferta}`;
          } else if (oferta.puede_participar && !oferta.calificacion_oferta) {
            return "Oferta registrada y no calificada";
          } else if (oferta) {
            return "Oferta registrada";
          } else {
            return "Oferta registrada y no evaluada";
          }
        })
        .join("\n");
    },
    //abrir el modal para agregar una nueva oferta dentro del modal de revision de participaciones
    async abrirModalAgregarOferta(participacionProveedor) {
      const lotes = participacionProveedor;
      let participacionProveedorEncontrada = null;

      for (const lote of lotes) {
        if (lote.participacionProveedor) {
          participacionProveedorEncontrada = lote.participacionProveedor;
          break;
        }
      }
      this.agregarOfertaAdjunto = true;
      this.dataCrearOfertaParticipacionProveedor =
        participacionProveedorEncontrada;
    },
    //metodo para crear una oferta nueva
    async crearRegistroDocumentoOferta() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        const id_participacion_proveedor =
          this.dataCrearOfertaParticipacionProveedor.id;

        const payload = {
          fecha: this.form.calendar,
          tipo_oferta: this.form.idTipoOferta,
          adjunto: this.form.adjunto,
        };
        const form = convertToFormData(payload);

        const { status } =
          await this.services.PacProcesos.agregarOfertaProveedorLotes(
            id_participacion_proveedor,
            form
          );

        if (status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La oferta ha sido registrada con éxito",
          });
          this.cerrarModal();
          this.modalAgregarParticipaciones();
        }
      }
    },
    //Metodo para cerrar el modal de agregar una nueva oferta
    cerrarModal() {
      this.form.calendar = null;
      this.form.idTipoOferta = null;
      this.form.adjunto = null;
      this.agregarOfertaAdjunto = false;
    },

    // abrir el modal para visualizar las calificaciones de la oferta
    async abrirModalCalificacion(oferta) {
      this.modalCalificacionOferta = true;
      this.calificacion_oferta = oferta[0]?.calificacion_oferta;
      this.dataOferta = oferta[0];
    },
    // Metodo para cerrar el modal de ver las calificaciones de las ofertas
    cerrarModalCalificaciones() {
      this.calificacion_oferta = null;
      this.modalCalificacionOferta = false;
    },
    // modificar la calificacion de la oferta para registrar o editar
    async putCalificacionOferta() {
      this.$v.calificacion_oferta.$touch();

      if (!this.$v.calificacion_oferta.$invalid) {
        const payload = {
          calificacion_oferta: this.calificacion_oferta,
        };
        const form = convertToFormData(payload);
        const { status } =
          await this.services.PacProcesos.agregarCalificacionOferta(
            this.dataOferta.id,
            form
          );

        if (status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Calificación actualizada",
          });
          this.cerrarModalCalificaciones();
          this.modalAgregarParticipaciones();
        }
      }
    },
    // modal para eliminar la oferta que tiene un proveedor y eliminarla por completo
    async eliminarModalCalificacion(oferta) {
      this.modalDeleteOferta = true;
      this.dataOfertaEliminacion = oferta[0];
    },
    async confirmarEliminacionOferta() {
      const { status } = await this.services.PacProcesos.eliminarOferta(
        this.dataOfertaEliminacion.id
      );
      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Oferta eliminada exitosamente",
        });
        this.modalDeleteOferta = false;
        this.modalAgregarParticipaciones();
      }
    },

    horasDisponibles() {
      let hora_maxima = [];

      let fecha_minima = null;

      if (this.form.calendar) {
        fecha_minima = this.form.calendar;
      } else {
        fecha_minima = new Date();
      }

      if (
        fecha_minima.getDate() === new Date().getDate() &&
        fecha_minima.getMonth() === new Date().getMonth() &&
        fecha_minima.getFullYear() === new Date().getFullYear()
      ) {
        hora_maxima = new Date().getHours();
      } else {
        hora_maxima = 23;
      }

      this.horas_disponibles = { max: hora_maxima, min: 0 };
    },
    textSwitch(lote) {
      // Verifica si el objeto `participacionLote` y la propiedad `participacion_aceptada` existen
      if (lote && lote.participacion_aceptada) {
        return "Participando en lote - Participando en subasta";
      } else if (lote) {
        return "Participando en lote";
      } else {
        return "No participando";
      }
    },

  },
  watch: {
    loteSeleccionado(value_param) {
      if (value_param != undefined) {
        this.getLote({ id_lote: this.lotes[value_param].id });
      }
    },
  },
  created: function () {
    this.getLotes({
      id_proceso_compra: this.$route.params.idProceso,
    });
    this.obtenerAdjuntoPermitirModificacionesPreCarga();
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  border: 1px solid #c2c2c2 !important;
  margin-bottom: 10px !important;
}

.v-list-item--active {
  color: white !important;
  border: 1px solid #4f5884 !important;
  background: var(--v-secondary-base) !important;
}

.small-text {
  font-size: 14px !important;
  color: #818181 !important;
}
</style>
