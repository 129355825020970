<template>
	<v-card>
		<v-card-actions>
			<v-row>
				<v-col>
					<ValidarEdicion
						:id_proceso="+this.id_proceso"
						:id_modificativa="6"
						@validar="validar"
					/>
				</v-col>
			</v-row>
		</v-card-actions>

		<v-card-text class="d-flex justify-center">
			<!-- Card dentro de la card principal -->
			<v-card style="width: 50%">
				<v-card-title>
          <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
            <v-icon class="mr-1" style="vertical-align: middle;">mdi-text-box-multiple-outline</v-icon>
            Permitir visualizar el cierre de contratos al proceso de compra
          </p>
        </v-card-title>
				<v-divider/>
        <v-card-text class="d-flex justify-center mt-2 mb-3">
					<div v-if="!validado">
						<p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1 mb-5" style="word-break: break-word;">
							El proceso de compra tiene: <strong>"{{ proceso_libre ? 'activado' : 'desactivado' }}"</strong> la pestaña de cierre de contratos.
						</p>
						<p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
							<v-icon class="mr-1" color="error white--text" style="vertical-align: middle;">mdi-close-octagon</v-icon>
							Debe subir un adjunto para realizar modificaciones.
						</p>
					</div>

					<div v-else>
						<v-switch
							v-model="proceso_libre"
							:label="procesoLibreText"
							hide-details
							@change="handleProcesoLibreChange"
						></v-switch>
					</div>
        </v-card-text>
			</v-card>
			<!-- Finaliza la card de la card principal -->
		</v-card-text>
	</v-card>
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import ValidarEdicion from "./ValidarEdicion.vue";
export default {
	name: "HabilitarProcesosLibres",
	components: { ValidarEdicion, ConfirmationDialog },
	computed: {
		id_proceso() {
			return this?.$route?.params?.idProceso || 0;
		},
		procesoLibreText() {
    return this.proceso_libre ? "Tiene activo la Tab de Cierre de contratos" : "No tiene activo la Tab de Cierre de contratos";
  }

	},
	data: () => ({
		proceso_libre: false,
		validado: false,
	}),
	methods: {
		validar(valor) {
			this.validado = valor;
		},
		async handleProcesoLibreChange() {
			const formData = new FormData();
			formData.append("proceso_libre", this.proceso_libre);

			const { status } = await this.services.ProcesosLibresServices.changeEstadoProcesoLibre(
				this.$route?.params?.idProceso,
				formData,
			);
			if (status === 201) {
				this.getProcesoLibre();
			}
		},
		async getProcesoLibre() {
			const { data, status } = await this.services.ProcesosLibresServices.listProcesoLibre(this.$route?.params?.idProceso);
			if (status === 200) {
				this.proceso_libre = data.proceso_libre;
			}
		},
	},
	async created() {
		this.getProcesoLibre();
	},
};
</script>
<style>
.highlight-row {
	background-color: #ecb36d;
}
</style>