<template>
  <v-card>
    <v-card-actions>
      <v-row>
        <v-col>
          <ValidarEdicion
            :id_proceso="+this.id_proceso"
            :id_modificativa="3"
            @validar="validar"
          />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <v-row>
        <v-col cols="6" md="4">
          <v-autocomplete
            outlined
            :items="proveedores"
            item-text="nombre_comercial"
            item-value="id"
            label="Seleccione el proveedor"
            @change="filtrar"
            v-model="options.id_proveedor"
            clearable
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-autocomplete
            outlined
            :items="lotes"
            item-text="nombre"
            item-value="id"
            label="Seleccione el lote"
            @change="filtrar"
            v-model="options.id_lote"
            clearable
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-switch
            v-model="options.borrados"
            label="Mostrar registros eliminados"
            color="success"
            @change="filtrar"
          />
        </v-col>
        <v-col cols="2" md="2" class="pt-5">
          <v-btn @click="reiniciarFiltros">Limpiar Filtros</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="4">
          <v-btn
            @click="showModalAgregar = true"
            class="secondary"
            :disabled="!validado"
          >
            Agregar Adjudicación
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :items="listado"
            :headers="headers"
            :server-items-length="+totalItems"
            @pagination="paginar"
            :item-class="getRowClass"
          >
            <template #[`item.monto`]="{ item }">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(item.monto)
              }}
            </template>
            <template #[`item.accion`]="{ item }">
              <app-menu-acciones
                :menu="getAcciones(item)"
                titulo="Acciones"
                :elemento="item"
              ></app-menu-acciones>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <modalAgregarAdjudicacion
        :id_proceso="id_proceso"
        :show="showModalAgregar"
        @cerrar="showModalAgregar = false"
        :lotes="lotes"
        :proveedores="proveedores"
        @cerrar_recargar="
          (showModalAgregar = false), obtenerListadoAdjudicaciones()
        "
      />
      <ConfirmationDialog
        :show="show_modal"
        title="¿Deseas eliminar la adjudicación?"
        message="El cambio es irreversible"
        btnConfirmar="Continuar"
        @close="show_modal = false"
        @confirm="eliminarAdjudicacion"
      />
      <modalCambiarProveedor
        :id_proceso_compra="id_proceso"
        :adjudicacion="adjudicacion"
        :proveedores="proveedores"
        :show="showModalCambiarProveedor"
        @cerrar="showModalCambiarProveedor = false"
        @cerrar_recargar="
          (showModalCambiarProveedor = false), obtenerListadoAdjudicaciones()
        "
      />
    </v-card-text>
  </v-card>
</template>
<script>
import ValidarEdicion from "./ValidarEdicion.vue";
import modalAgregarAdjudicacion from "./modalAgregarAdjudicacion.vue";
import modalCambiarProveedor from "./modalCambiarProveedor.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "reorganizacion_adjudicacion",
  components: {
    ValidarEdicion,
    modalAgregarAdjudicacion,
    modalCambiarProveedor,
    ConfirmationDialog,
  },
  computed: {
    id_proceso() {
      return +this?.$route?.params?.idProceso || 0;
    },
  },
  methods: {
    validar(valor) {
      this.validado = valor;
    },
    async filtrar() {
      this.reiniciarPaginacion();
      await this.obtenerListadoAdjudicaciones();
    },
    async obtenerListadoAdjudicaciones() {
      const {
        data,
        headers: { total_rows },
      } = await this.services.AdjudicacionServices.listAdjudicaciones(
        this.id_proceso,
        this.options
      );
      this.totalItems = total_rows;
      this.listado = data;
    },
    async paginar({ page, itemsPerPage }) {
      this.options.page = page;
      this.options.per_page = itemsPerPage;
      await this.obtenerListadoAdjudicaciones();
    },
    async obtenerProveedores() {
      const { data } = await this.services.AdjudicacionServices.listProveedores(
        this.id_proceso
      );
      this.proveedores = data;
    },
    reiniciarPaginacion() {
      this.options.page = 1;
      this.options.per_page = 1;
    },
    async reiniciarFiltros() {
      this.options.id_proveedor = null;
      this.options.id_lote = null;
      this.options.borrados = null;
      await this.obtenerListadoAdjudicaciones();
    },
    async obtenerLotes() {
      const { data } = await this.services.AdjudicacionServices.listLotes(
        this.id_proceso
      );
      this.lotes = data;
    },
    async eliminarAdjudicacion() {
      await this.services.AdjudicacionServices.eliminarAdjudicacion(
        this.adjudicacion.id,
        this.id_proceso
      );
      this.show_modal = false;
      this.adjudicacion = null;
      await this.obtenerListadoAdjudicaciones();
    },
    getAcciones(item) {
      return [
        {
          nombre: "Eliminar adjudicación",
          icono: "mdi-delete",
          callback2: () => (
            (this.adjudicacion = item), (this.show_modal = true)
          ),
          disabled: !!item.deleted_at || !this.validado,
        },
        {
          nombre: "Cambiar proveedor",
          icono: "mdi-briefcase-arrow-left-right",
          callback2: () => (
            (this.adjudicacion = item), (this.showModalCambiarProveedor = true)
          ),
          disabled: !!item.deleted_at || !this.validado,
        },
      ];
    },
    getRowClass(item) {
      if (item.deleted_at) {
        return "highlight-row";
      }
      return "";
    },
  },
  data: () => ({
    validado: false,
    listado: [],
    lotes: [],
    options: {
      page: 1,
      per_page: 10,
    },
    headers: [
      {
        text: "Lote adjudicado",
        align: "center",
        value: "spo.lotes_procesos.nombre",
      },
      {
        text: "Agrupador adjudicado",
        align: "center",
        value: "spo.nombre_agrupacion",
      },
      { text: "Nombre", align: "center", value: "gobs.Proveedor.nombre" },
      {
        text: "Nombre comercial",
        align: "center",
        value: "gobs.Proveedor.nombre_comercial",
      },
      { text: "Cantidad adjudicada", align: "center", value: "cantidad" },
      { text: "Monto", align: "center", value: "monto" },
      { text: "Acciones", align: "center", value: "accion" },
    ],
    totalItems: 0,
    proveedores: [],
    showModalAgregar: false,
    adjudicacion: null,
    showModalCambiarProveedor: false,
    show_modal: false,
  }),
  watch: {
    async validado(val) {
      if (val) {
        await this.obtenerListadoAdjudicaciones();
      }
    },
  },
  async created() {
    await this.obtenerListadoAdjudicaciones();
    await this.obtenerProveedores();
    await this.obtenerLotes();
  },
};
</script>
<style>
.highlight-row {
  background-color: #ecb36d;
}
</style>
